import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0eec9347"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = ["controlslist", "webkit-playsinline", "src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("video", {
    id: "videoo",
    ref: "videoPlayer",
    class: "myvideo",
    controlslist: _ctx.noplaybackrate,
    muted: true,
    "webkit-playsinline": _ctx.isReady == 1,
    src: _ctx.videos,
    controls: "",
    onPlay: _cache[0] || (_cache[0] = //@ts-ignore
    function () {
      return _ctx.play && _ctx.play.apply(_ctx, arguments);
    }),
    onPause: _cache[1] || (_cache[1] = //@ts-ignore
    function () {
      return _ctx.pause && _ctx.pause.apply(_ctx, arguments);
    })
  }, null, 40, _hoisted_1)]);
}