import "core-js/modules/es.number.constructor.js";
import useCurrentInstance from "@/hooks/useCurrentInstance";
import { defineComponent, onMounted, ref, watch } from "vue";
export default defineComponent({
  name: "AtDetail",
  props: {
    videourl: {
      type: String,
      default: ''
    },
    isReadyvideo: {
      type: Number,
      default: 1
    },
    setTime: {
      type: Number,
      default: 0
    },
    readTime: {
      type: Number,
      default: 0
    },
    // videoId:{
    //   type: Number,
    //   default: 0,
    // },
    processId: {
      type: Number,
      default: 0
    },
    downloadId: {
      type: Number,
      default: 0
    }
  },
  emits: ['pause'],
  setup: function setup(props, _ref) {
    var emit = _ref.emit;

    var _useCurrentInstance = useCurrentInstance(),
        globalProperties = _useCurrentInstance.globalProperties;

    var noplaybackrate = ref("nodownload");
    var isReady = ref(1); //是否为第一次观看

    isReady.value = props.isReadyvideo || 1;
    var Fixedtime = ref(0); //固定开始时间

    var stop = ref(false);
    var outtime = 0;
    var videos = ref(props.videourl);
    var videoPlayer = ref({
      currentTime: 0
    });
    var alltime = ref(0);
    onMounted(function () {
      Fixedtime.value = props.setTime || 0;

      if (isReady.value == 1) {
        noplaybackrate.value = 'noplaybackrate nodownload';
      }

      playBySeconds();
    });
    watch(function () {
      return props.setTime;
    }, function (nwes) {
      videoPlayer.value.currentTime = nwes;
    });

    function pause() {
      stop.value = true;
      emit("pause", outtime, Number(alltime.value) - Number(outtime));
    }

    function play() {
      stop.value = false;
    }

    function playBySeconds() {
      videoPlayer.value.play();

      if (Fixedtime.value > 0) {
        videoPlayer.value.currentTime = Fixedtime.value;
      }

      videoPlayer.value.ontimeupdate = function (e) {
        if (alltime.value == 0) {
          var htm = document.getElementById("videoo");
          alltime.value = htm.duration;
        }

        var jump_time = 0;

        if (outtime == 0) {
          jump_time = parseInt(String(Fixedtime.value)) + parseInt(String(outtime));
        } else {
          jump_time = parseInt(String(outtime));
        }

        if (isReady.value == 1) {
          if (videoPlayer.value != null && videoPlayer.value.currentTime - jump_time > 3) {
            videoPlayer.value.currentTime = outtime;
          }
        }

        if (videoPlayer.value != null) {
          outtime = videoPlayer.value.currentTime;
        }
      };
    }

    function add() {
      videoPlayer.value.pause();
    } // document.addEventListener('visibilitychange', async()=>{
    //   if(props.videoId){
    //     await globalProperties?.$api["updateRecord"]({
    //       newsId: Number(props.videoId),
    //       processId: props.processId,
    //       studyTime: Math.trunc(outtime),
    //       download: props.downloadId,
    //     });
    //   }
    //   },false)
    //onBeforeUnmount(async()=>{
    // if(props.videoId && !stop.value){
    //   await globalProperties?.$api["updateRecord"]({
    //     newsId: Number(props.videoId),
    //     processId: props.processId,
    //     studyTime: Math.trunc(outtime)-Math.trunc(props.readTime),
    //     download: props.downloadId,
    //   });
    // }
    //})


    return {
      noplaybackrate: noplaybackrate,
      videos: videos,
      videoPlayer: videoPlayer,
      pause: pause,
      play: play,
      isReady: isReady,
      add: add
    };
  }
});